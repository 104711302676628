export const format = {
  methods: {
    emailValidate(email) {
      const exptext = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

      // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (exptext.test(email) == false) {
        return false;
      }
    },

    nameValidate(name) {
      const maxByte = 20; //최대 100바이트
      const text_len = name.length; //입력한 문자수

      let totalByte = 0;
      for (let i = 0; i < text_len; i++) {
        const each_char = name.charAt(i);
        const uni_char = escape(each_char); //유니코드 형식으로 변환
        if (uni_char.length > 4) {
          // 한글 : 2Byte
          totalByte += 2;
        } else {
          // 영문,숫자,특수문자 : 1Byte
          totalByte += 1;
        }
      }

      if (totalByte > maxByte || totalByte < 3) {
        return false;
      }
    },
    convertDateFormat(date) {
      if (!date) return "";
      let year = date.slice(0, 4);
      let month = date.slice(4, 6);
      let day = date.slice(6, 8);
      let hour = date.slice(8, 10);
      let minutes = date.slice(10, 12);
      let seconds = date.slice(12, 14);
      // let obj = year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
      let obj = `${year}-${month}-${day}${hour ? " " + hour + ":" + minutes + ":" + seconds : ""}`;
      return `${obj}`;
    },
    convertMobileFormat(mobilNo) {
      let head = mobilNo.slice(0, 3);
      let middle = mobilNo.slice(3, 7);
      let end = mobilNo.slice(7, 11);
      let obj = head + "-" + middle + "-" + end;
      return `${obj}`;
    },
    mobileNumberCheck(mobileNo) {
      var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (regPhone.test(mobileNo) == false) {
        return false;
      }
    },
    HourMinuteFormat(value) {
      let hour = value.slice(0, 2);
      let minute = value.slice(2, 4);
      let obj = hour + ":" + minute;
      return `${obj}`;
    },
    numberFormat(inputNumber) {
      if (inputNumber === 0) return "0";
      if (!inputNumber) return "";
      return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setNavbar(visible, category) {
      let data = {
        visible: visible,
        category: category,
      };
      this.$store.dispatch("SET_NAVBAR", data);
    },
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // getPhoneMask(val) {
    //   let res = this.getMask(val);
    //   this.contact = res;
    // },
    getMask(phoneNumber) {
      if (!phoneNumber) return phoneNumber;
      phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

      let res = "";
      if (phoneNumber.length < 3) {
        res = phoneNumber;
      } else {
        if (phoneNumber.substr(0, 2) == "02") {
          if (phoneNumber.length <= 5) {
            //02-123-5678
            res = phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2, 3);
          } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
            //02-123-5678
            res = phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2, 3) + "-" + phoneNumber.substr(5);
          } else if (phoneNumber.length > 9) {
            //02-1234-5678
            res = phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2, 4) + "-" + phoneNumber.substr(6);
          }
        } else {
          if (phoneNumber.length < 8) {
            res = phoneNumber;
          } else if (phoneNumber.length == 8) {
            res = phoneNumber.substr(0, 4) + "-" + phoneNumber.substr(4);
          } else if (phoneNumber.length == 9) {
            res = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
          } else if (phoneNumber.length == 10) {
            res = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
          } else if (phoneNumber.length > 10) {
            //010-1234-5678
            res = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 4) + "-" + phoneNumber.substr(7);
          }
        }
      }
      return res;
    },
    getBirthday(birthday) {
      if (!birthday) return birthday;
      birthday = birthday.replace(/[^0-9]/g, "");
      let res = "";
      if (birthday.length < 8) {
        res = birthday;
      } else {
        if (birthday.length == 8) {
          res = birthday.substr(0, 8) + "-" + birthday.substr(8);
        }
        // else if (birthday.length > 8 && birthday.lengh < 11) {
        //   window.console.log("birthday.length", birthday.length)
        //   res = birthday.substr(0, 8) + "-" + birthday.substr(8, 9);
        // }
        else if (birthday.length == 9) {
          res = birthday.substr(0, 8) + "-" + birthday.substr(8);
        } else {
          let temp = birthday.substr(0, 9);
          res = temp.substr(0, 8) + "-" + temp.substr(8);
        }
      }
      return res;
    },
    checkPassword(str) {
      let pw = str;
      let num = pw.search(/[0-9]/g);
      let eng = pw.search(/[a-z]/gi);
      let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

      if (pw.length < 8 || pw.length > 16) {
        alert("비밀번호는 8자리 ~ 16자리 이내로 입력해주세요.");
        return false;
      } else if (pw.search(/₩s/) != -1) {
        alert("비밀번호는 공백없이 입력해주세요.");
        return false;
      } else if (num < 0 || eng < 0 || spe < 0) {
        alert("비밀번호는 영문,숫자,특수문자를 혼합하여 입력해주세요.");
        return false;
      } else return true;
    },
  },
  // watch: {
  //   price(newValue) {
  //     const result = newValue.toString().replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     Vue.nextTick(() => this.price = result);
  //   }
  // };
};
