<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>웨딩 컬렉션 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="title">상품명</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <div class="labelBox">
          <label>
            <input type="radio" value="" checked v-model="category" />전체
          </label>
          <label>
            <input type="radio" value="STUDIO" v-model="category" />
            사진
          </label>
          <label>
            <input type="radio" value="DRESS" v-model="category" />
            드레스/예복
          </label>
          <label>
            <input type="radio" value="MAKEUP" v-model="category" />
            헤어&#38;메이크업/부케
          </label>
          <label>
            <input type="radio" value="HONEYMOON" v-model="category" />
            허니문
          </label>
          <label>
            <input type="radio" value="VIDEO" v-model="category" />
            웨딩 영상
          </label>
          <label>
            <input type="radio" value="EVENT" v-model="category" />
            주례&#38;사회/이벤트
          </label>
          <label>
            <input type="radio" value="GIFT" v-model="category" />
            예물
          </label>
          <label>
            <input type="radio" value="HANBOK" v-model="category" />
            한복/예단
          </label>
          <label>
            <input type="radio" value="PYEBAEK" v-model="category" />
            폐백&#38;이바지/청첩장
          </label>
          <label>
            <input type="radio" value="BEAUTY" v-model="category" />
            뷰티케어
          </label>
          <label>
            <input type="radio" value="INTERIOR" v-model="category" />
            가전/가구/인테리어
          </label>
          <label>
            <input type="radio" value="CATERING" v-model="category" />
            케이터링
          </label>
        </div>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one collection">
      <h2 class="bold">
        웨딩 컬렉션 리스트
        <button class="right basic">
          <router-link to="/admin/collectionmanage/register"
            >웨딩 컬렉션 등록</router-link
          >
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 8%" />
          <col style="width: 13%" />
          <col style="width: 16%" />
          <col style="width: 36%" />
          <col style="width: 12%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <th>브랜드명</th>
          <th>상품정보</th>
          <th class="right">가격</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in collectionList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>
            {{
              data.category == "STUDIO"
                ? "사진"
                : data.category == "DRESS"
                ? "드레스/예복"
                : data.category == "MAKEUP"
                ? "헤어&#38;메이크업/부케"
                : data.category == "HONEYMOON"
                ? "허니문"
                : data.category == "VIDEO"
                ? "웨딩영상"
                : data.category == "EVENT"
                ? "주례&#38;사회/이벤트"
                : data.category == "GIFT"
                ? "예물"
                : data.category == "HANBOK"
                ? "한복/예단"
                : data.category == "PYEBAEK"
                ? "폐백&#38;이바지/청첩장"
                : data.category == "BEAUTY"
                ? "뷰티케어"
                : data.category == "INTERIOR"
                ? "가전/가구/인테리어"
                : data.category == "CATERING"
                ? "케이터링"
                : ""
            }}
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>{{ data.content }}</td>
          <td class="right">{{ numberFormat(data.price) }}원</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="collectionList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchCollectionList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      collectionList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    this.getCollectionList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.collectionList = [];
      this.total = 0;
      this.getCollectionList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getCollectionList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCollectionList();
    },
    handleMove(id) {
      this.$router.push({ name: "collectionregister", query: { id: id } });
    },
    getCollectionList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
      };
      fetchCollectionList(params).then((res) => {
        if (res.data.status == 200) {
          this.collectionList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
